import PropTypes from "prop-types"
import React, { useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Logo from '../img/logo/logo.png';
import LogoMaster from '../img/logo/logo_master.png';
import { Link } from 'gatsby';
import cl from 'classnames';

const _id = 'menubtn';

const Header = ({ siteTitle, pageContext }) => {
  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
    null,
    false,
    300
  )

  return <header className={cl("header", !hideOnScroll && 'gray')}>
    <div className="container">
      <a href="/" className="logo">
        <img src={LogoMaster} alt='СК Мастер' className='logo_master' />
      </a>
     
      <input className="menu-btn" type="checkbox" id={_id} name={_id} />
      <label className="menu-icon" htmlFor={_id}><span className="navicon"></span></label>
      <ul className="menu">
        {
          pageContext && pageContext.menus && pageContext.menus.main && pageContext.menus.main.map(item => <li key={item.identifier}>
            <Link to={item.url}>{item.title}</Link>
          </li>)
        }
      </ul>
      <img src={Logo} alt='СК Мастер' className='logoimage' />
    </div>
  </header>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
