/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import "../css/bootstrap-grid.min.css";
import "../css/bootsnav.css";
import "../css/style.css";

const Layout = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    document.body.classList.add('loaded-progress');
    setTimeout(() => {
      document.body.classList.add('loaded');
    }, 400);
  }, []);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} pageContext={pageContext} />
      <div>
        <main>{children}</main>
        <footer className="footer-area bg-dark white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                {/* <div className="footer-social-link">
                  <ul>
                    <li><a href=""><i className="fa fa-facebook"></i></a>
                    </li>
                    <li><a href=""><i className="fa fa-twitter"></i></a>
                    </li>
                    <li><a href=""><i className="fa fa-dribbble"></i></a>
                    </li>
                    <li><a href=""><i className="fa fa-google-plus"></i></a>
                    </li>
                  </ul>
                </div> */}
                <div className="footer-menu">
                </div>
                <div className="footer-text">
                  <h6>©copyright | {new Date().getFullYear()} Ск-Мастер</h6>
                </div>
              </div>
            </div>
          </div>
        </footer>


      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
